import Subject from "../../../../components/system-sections/system-top/subject";
import ProgressBar from "./progress-bar";
import CountdownCircle from "../../../../components/system-sections/system-top/countdown-circle";
import React from "react";
const QuestionsTop = (props) => {
  const { subjectName, subjectCode, End, Questions, setLoad } = props;

  return (
    <div className="questions__content__top">
      <Subject Title={subjectName} Code={subjectCode} />
      <ProgressBar Quetsions={Questions.length} End={End} />
      <CountdownCircle setLoad={setLoad} Questions={Questions} step={End} />
    </div>
  );
};

export default QuestionsTop;
