import Cookies from "js-cookie";
import QuestionsTop from "./components/questions-top";
import QuestionsContainer from "./components/questions-container/index";
import { useEffect, useLayoutEffect, useState } from "react";
import { requestHeaders, BACKEND_URL } from "../../utils/headers";

import axios from "axios";
import Loading from "../../components/loading/loading";
import { AddQuestions } from "./components/value";
import axiosInstance from "../../api/axios_instance";

export default function Questions() {
  const [matchingQuestions, setMatchingQuestions] = useState([]);
  const [nonMatchingQuestions, setNonMatchingQuestions] = useState([]);
  const [subjectName, setSubjectName] = useState("");
  const [subjectCode, setSubjectCode] = useState("");
  const [lastAnsweredQuestionId, setLastAnsweredQuestionId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [load, setLoad] = useState(true);
  const [Questions, setQuestions] = useState([]);
  useEffect(() => {
    const s = Questions.findIndex((itm) =>
      itm.question.map((q) => q.questionId).includes(lastAnsweredQuestionId)
    );
    console.log(s, Questions);

    setStep(s >= 0 ? s : 0);
  }, [Questions, lastAnsweredQuestionId]);

  const onBeforeUnload = (ev) => {
    return (ev.returnValue = "اذاا غادرت فلن تستطيع العودة بدون اذن المشرف.");
  };
  useEffect(() => {
    if (load) window.addEventListener("beforeunload", onBeforeUnload);
    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, [load]);

  useEffect(() => {
    const StudentId = localStorage.getItem("user-study-study");
    const TestId = Cookies.get("testid-study");
    GetData(
      StudentId,
      TestId,
      setSubjectCode,
      setSubjectName,
      setMatchingQuestions,
      setNonMatchingQuestions,
      setLoading,
      setQuestions,
      setStep,
      setLastAnsweredQuestionId
    );
  }, []);

  if (loading === false)
    return (
      <section className="questions">
        <Loading />
      </section>
    );

  return (
    <section className="questions">
      <div className="container">
        <div className="questions__content">
          <QuestionsTop
            setLoad={setLoad}
            subjectName={subjectName}
            subjectCode={subjectCode}
            End={step}
            Questions={Questions}
          />

          {Questions.length && (
            <QuestionsContainer
              key={JSON.stringify(Questions)}
              MatchingQuestions={matchingQuestions}
              NonMatchingQuestions={nonMatchingQuestions}
              Questions={Questions}
              Step={step}
              setStep={setStep}
            />
          )}
        </div>
      </div>
    </section>
  );
}
const GetData = async (
  StudentId,
  TestId,
  setSubjectCode,
  setSubjectName,
  setMatchingQuestions,
  setNonMatchingQuestions,
  setLoading,
  setQuestions,
  setStep,
  setLastAnsweredQuestionId
) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/StartTest`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setSubjectCode(response.data.responseObject.subjectCode);
      setSubjectName(response.data.responseObject.subjectName);
      AddQuestions(
        response.data.responseObject.matchingQuestions,
        response.data.responseObject.nonMatchingQuestions,
        setQuestions
      );
      setMatchingQuestions(response.data.responseObject.matchingQuestions);
      setNonMatchingQuestions(
        response.data.responseObject.nonMatchingQuestions
      );
      // setStep(
      //   response.data.responseObject.lastAnsweredQuestionOrder
      //     ? response.data.responseObject.lastAnsweredQuestionOrder - 1
      //     : 0
      // );
      setLastAnsweredQuestionId(
        response.data.responseObject.lastAnsweredQuestionId
      );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
