/* eslint-disable eqeqeq */
import { requestHeaders, BACKEND_URL } from "../../../../utils/headers";
import { useNavigate } from "react-router";

import Cookies from "js-cookie";
import axios from "axios";
import ListQuestions from "./list-questions";
import QuestionsReceipt from "./questions-receipt";
import swal from "sweetalert";
import axiosInstance from "../../../../api/axios_instance";
const QuestionMatching = (props) => {
  const { Quetsions, Step, setStep, setActive } = props;
  const objectAnswers = [];
  const objectArrayAnswers = [];
  return (
    <>
      <div className="questions__content__container">
        <div className="questions__content__container__content">
          <h3>اختار رقم السؤال المناسب للاجابه </h3>
          <QuestionsReceipt
            Question={Quetsions[Step]}
            objectAnswers={objectAnswers}
            objectArrayAnswers={objectArrayAnswers}
          />
        </div>
        <ListQuestions Quetsions={Quetsions} Step={Step} />
      </div>
      <QuestionsEnd
        Quetsions={Quetsions}
        Step={Step}
        setStep={setStep}
        setActive={setActive}
      />
    </>
  );
};

export default QuestionMatching;

const QuestionsEnd = (props) => {
  const { Quetsions, Step, setStep, setActive } = props;

  const StudentId = localStorage.getItem("user-study-study");
  const TestId = Cookies.get("testid-study");
  const navigate = useNavigate();

  const handleNext = () => {
    SendData(StudentId, TestId, Step, setStep, Quetsions, setActive, navigate);
  };

  return (
    <div className="questions__content__container__end">
      <button
        className="btn btn-back"
        type="button"
        onClick={() => {
          if (Step == 0) {
            // navigate(`/details/${TestId}`);
          } else {
            setStep(Step - 1);
          }
        }}
      >
        <IconBack />
        <span className="text">السابق</span>
      </button>
      <button
        id="force-next"
        className="btn btn-next"
        type="button"
        onClick={handleNext}
      >
        <span className="text">التالى</span>
        <IconNext />
      </button>
    </div>
  );
};

const SendData = async (
  StudentId,
  TestId,
  Step,
  setStep,
  Quetsions,
  setActive,
  navigate
) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  for (let i = 0; i < Quetsions[Step].EndAnswer.length; i++) {
    if (Quetsions[Step].EndAnswer[i].questionId == undefined) {
      swal("يجب الاجابه علي جميع الاسئله", {
        icon: "warning",
        timer: 1000,
        className: "swal-contact-us",
      });
      return false;
    } else if (Quetsions[Step].EndAnswer.answerId !== "") {
      data.append(
        `testAnswers[${i}].answerId`,
        Quetsions[Step].EndAnswer[i].answerId
      );
      data.append(
        `testAnswers[${i}].questionId`,
        Quetsions[Step].EndAnswer[i].questionId
      );
    }
  }

  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/SaveStudentAnswer`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description == "Success") {
        if (Step === Quetsions.length - 1) {
          navigate("/end-questions");
        } else {
          const number = +Step;
          setStep(number + 1);
          setActive(true);
        }
      } else {
        const Error = response.data.responseStatus.errors[0].errorMessage;
        swal(Error, {
          icon: "error",
          timer: 1000,
          className: "swal-contact-us",
        });
      }
    })
    .catch(function (error) {
      const Error = error.response.data.Errors.TestAnswers[1];
      swal(Error, {
        icon: "error",
        timer: 1000,
        className: "swal-contact-us",
      });
    });
};

const IconNext = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M19 8.3125H4.54812L11.1803 1.68031L9.5 0L0 9.5L9.5 19L11.1803 17.3197L4.54812 10.6875H19V8.3125Z"
        fill="white"
      />
    </svg>
  );
};

const IconBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M0 10.6875L14.4519 10.6875L7.81969 17.3197L9.5 19L19 9.5L9.5 0L7.81969 1.68031L14.4519 8.3125L0 8.3125V10.6875Z"
        fill="#B7BBC9"
      />
    </svg>
  );
};
