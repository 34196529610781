import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import HomeDiscription from "./components/home-discription";
import HomeTop from "./components/home-top";
import axios from "axios";
import { requestHeaders, BACKEND_URL } from "../../utils/headers";

import Loading from "../../components/loading/loading";
import axiosInstance from "../../api/axios_instance";

const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const StudentId = localStorage.getItem("user-study-study");
    GetData(StudentId, setData, setLoading);
  }, []);

  if (loading === false)
    return (
      <section className="home">
        <Loading />
      </section>
    );

  return (
    <section className="home">
      <div className="container">
        <div className="home__content">
          <HomeTop
            Title={"المواد المتاحة للاختبار"}
            Discription={`اسم الدورة : ${data[0]?.classRoomName}`}
          />
          {data && data.length ? (
            <div className="home__content__row-data">
              {data.map((item, index) => (
                <HomeDiscription
                  Day={item.testDateTime}
                  code={item.subjectCode}
                  Subject={item.subjectName}
                  item={item}
                  key={index}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default Home;

const GetData = async (StudentId, setData, setLoading) => {
  var data = new FormData();
  data.append("id", StudentId);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/GetAvailableTests`,
    headers: requestHeaders,
    data,
  };
  console.log(data);

  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);

      if (response.data.responseObject) {
        Cookies.remove("class-room-name");
        Cookies.set(
          "class-room-name",
          response.data.responseObject[0].classRoomName
        );
      }
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
