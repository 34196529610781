const DetailsEnd = (props) => {
  const { show } = props;
  const NextPage = () => {
    window.location.href = `/questions`;
  };

  return (
    <div className="details__content__end">
      {show && (
        <button className="btn btn-logout" type="button" onClick={NextPage}>
          بدء الاختبار
        </button>
      )}
    </div>
  );
};

export default DetailsEnd;
